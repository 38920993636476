import Nzh from "nzh"

import {
  DeepLoop
} from './deepLoop/deepLoop'

import dayjs from '@/plugins/dayjs'

const nzhcn = Nzh.cn;

const deppLoopIns = new DeepLoop()


// 渠道 1=官网 2=百度广告1 3=百度广告2 4=360广告
export const officialWebChannel2ch: string[] = ['', '官网', '百度广告1', '百度广告2', '360广告1']

export const shareConfirmOptions = {
  lockScroll: true,
  closeOnClickModal: false,
  closeOnPressEscape: false,
  confirmButtonText: "确定",
  cancelButtonText: "取消",
};

export function getCurrentMonthRange(range: number = 1, format: string = 'YYYY-MM-DD') {
  const currentDay = (dayjs() as any).endOf('month').endOf('day').format(format);

  const curMonthStartDay = (dayjs().subtract(range, "month").startOf("month").startOf('day') as any).format(format);


  return [curMonthStartDay, currentDay];
}

export function getCommonList(textList: any[], startIndex: number = 0) {
  const list: any[] = []

  for (let i = startIndex; i < textList.length; ++i) {
    const typeName = textList[i]

    list.push({
      label: typeName,
      value: i
    })
  }

  return list
}

export const chineseNum2Ch: string[] = [
  '',
  '一',
  '二',
  '三',
  '四',
  '五',
  '六',
  '七',
  '八',
  '九',
  '十'
]


export function toChineseNumber(num: number): string {
  // const chineseNumbers: string[] = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  // const units: string[] = ['', '十', '百', '千', '万'];
  // let str: string = '';

  // if (num === 0) {
  //   return chineseNumbers[0];
  // }

  // const numStr: string = num.toString();

  // for (let i = 0; i < numStr.length; i++) {
  //   str += chineseNumbers[Number(numStr[i])] + units[numStr.length - i - 1];
  // }

  // return str.replace(/零[千百十]/g, '零').replace(/^零/, '');

  return nzhcn.encodeS(num)
}

// 店铺类型 0未知 1台球 2棋牌  3台球商家端  4棋牌商家端  5(8号玩家)
export const shareShopType2ch = ["", "熊猫球社", "熊猫国粹馆", "熊猫球社商家端", "熊猫国粹馆商家端", "8号玩家"]

// 仅用户端  类型:1熊猫球社2熊猫国粹馆3众享台球
export const shareUserShopType2ch = ["", "熊猫球社", "熊猫国粹馆", "众享台球"]


// 品牌类型 1熊猫 2麦力士（众享）
export const brandType2Ch: string[] = ['', '熊猫', '众享']

// 类型 1麦力士 2沐尘 3熊猫球社(传0或不传为所有)
export const projectWebSitType2Ch: string[] = ['', '麦力士', '沐尘', '熊猫球社']

// function getShopTypeList() {
//   const list: any[] = []

//   for (let i = 1; i < shareShopType2ch.length; ++i) {
//     const typeName = shareShopType2ch[i]

//     list.push({
//       label: typeName,
//       value: i
//     })
//   }

//   return list
// }

export const chooseShopTypeList: any[] = getCommonList(shareShopType2ch, 1)

// 比赛类型:1平打 2分档让球 3弹性让球
export const leagueType2Text: string[] = [
  '',
  '平打',
  '分档让球',
  '弹性让球',
  '分级弹性让球'
]


export const chooseLeagueTypeList: any[] = getCommonList(leagueType2Text, 1)

// 比赛等级:1会员联赛 2乙级联赛 3甲级联赛 4超级联赛 5学生联赛
export const leagueLevelName2Text: string[] = [
  '',
  '会员联赛',
  '乙级联赛',
  '甲级联赛',
  '超级联赛',
  '学生联赛'
]

export const chooseLeagueLevelList: any[] = getCommonList(leagueLevelName2Text, 1)

// 奖励等级:1冠军 2亚军 3季军 4殿军 5:5-8名 6:9-16名 7:17-32名 8:参赛奖 9:33-64名 10:65-128名
export const leagueLevel2Text: string[] = [
  '',
  '冠军',
  '亚军',
  '季军',
  '殿军',
  '5~8名',
  '9~16名',
  '17~32名',
  '参赛奖',
  '33~64名',
  '65~128名'
]

export const leagueLevelList: any[] = getCommonList(leagueLevel2Text, 1)


// 判断是否为空
export function isEmptyLeagueReward(rewardItem: any): boolean {
  const {
    level,
    cash,
    shop_member_price,
    coupon_shop_id,
    coupon_num,
    reward_text
  } = rewardItem || {}

  if (!level) return true

  if (cash) return false

  if (shop_member_price) return false

  if (coupon_shop_id && coupon_num) return false

  if (reward_text) return false

  return true
}
// 获取显示奖励信息文案
export function getLeagueRewardTxt(rewardItem: any): string {
  const {
    level,
    cash,
    shop_member_price,
    coupon_name,
    coupon_shop_id,
    coupon_num,
    reward_text
  } = rewardItem || {}

  if (isEmptyLeagueReward(rewardItem)) return '';

  let reward_txt = ''

  if (cash) {
    reward_txt += `现金${cash}元`
  }

  if (shop_member_price) {
    reward_txt += `${reward_txt ? ',' : ''}单店卡${shop_member_price}元`
  }

  if (coupon_shop_id && coupon_name) {
    reward_txt += `${reward_txt ? ',' : ''}${coupon_name}x${coupon_num}`
  }

  if (reward_text) {
    reward_txt += `${reward_txt ? ',' : ''}${reward_text}`
  }

  return reward_txt
}


// 正赛对局类型
// 对局设置类型: 3(半决赛) 4(8进4) 5(16进8) 6(32进16) 7(64进32)
export const leagueSetType2Text: string[] = [
  '',
  '',
  '',
  '半决赛',
  '8进4',
  '16进8',
  '32进16',
  '64进32'
]


export const leagueSetTypeList: any[] = getCommonList(leagueSetType2Text, 3)


// 比赛规则类型 1中式台球 2自定义 3上传图片
export const leagueRuleType2Text: string[] = [
  '',
  '中式台球',
  '自定义',
  '上传图片'
]

export const leagueRuleTypeList: any[] = getCommonList(leagueRuleType2Text, 1)

// 店铺类型 1普通 2品牌 3旗舰
export const shareShopTag2ch = ['', '普通', '品牌', '旗舰']

function getShopTagSelectList() {
  const list: any[] = []

  for (let i = 1; i < shareShopTag2ch.length; ++i) {
    const typeName = shareShopTag2ch[i]

    list.push({
      label: typeName,
      value: i
    })
  }

  return list
}

export const chooseShopTagList: any[] = getShopTagSelectList()


export const userLevel2ch: any = {
  "0": "",
  "1": "黄金会员",
  "2": "钻石会员",
  "3": "星耀会员",
  "4": "王者会员",
};

export const transferUserLevel2ch: any[] = [
  "",
  "黄金会员",
  "钻石会员",
  "星耀会员",
  "王者会员",
];

export const defaultUserMemberList: any[] = [
  {
    label: "全部",
    value: -1,
  },
  {
    label: "黄金会员",
    value: 1,
  },
  {
    label: "钻石会员",
    value: 2,
  },
  {
    label: "星耀会员",
    value: 3,
  },
  {
    label: "王者会员",
    value: 4,
  },
]

export const defaultWeekList: any[] = [
  {
    label: '周一',
    value: 1
  },
  {
    label: '周二',
    value: 2
  },
  {
    label: '周三',
    value: 3
  },
  {
    label: '周四',
    value: 4
  },
  {
    label: '周五',
    value: 5
  },
  {
    label: '周六',
    value: 6
  },
  {
    label: '周日',
    value: 7
  }
]

export const number2CN: string[] = [
  '',
  '一',
  '二',
  '三',
  '四',
  '五',
  '六',
  '七',
  '八',
  '九',
  '十'
]

function getBoardInfoItem(...args: any[]) {
  const item: any = Object.create(null)

  if (args.length == 0) throw new Error('at leaset one param of Function getBoardInfoItem')

  item.total = args[0]

  if (args.length > 1) {
    item.today = args[1] || 0

    item.yesterday = args[2] || 0

    item.month = args[3] || 0
  }

  return item
}

function refreshDataBlockItem(total: any, today: any, yesterday: any, month: any, keys: string[], targetList: any[]) {
  const ob: any = {
    total,
    today,
    yesterday,
    month
  }
  function generateBoardInfoParams(ob: any, dataKey: string) {
    const params: any[] = []
    const keys = ['total', 'today', 'yesterday', 'month']

    for (const key of keys) {
      if (dataKey in ob[key]) {
        params.push(ob[key][dataKey])
      }
    }

    return params
  }

  for (let i = 0, len = keys.length; i < len; ++i) {
    const boardInfoItem = getBoardInfoItem(...generateBoardInfoParams(ob, keys[i]))

    if ('total' in boardInfoItem) {
      targetList[i].value = boardInfoItem['total']
    }

    if ('today' in boardInfoItem) {
      targetList[i].changeVars.todayIncrease = boardInfoItem['today']
    }

    if ('yesterday' in boardInfoItem) {
      targetList[i].changeVars.yesterdayIncrease = boardInfoItem['yesterday']
    }

    if ('month' in boardInfoItem) {
      targetList[i].changeVars.monthIncrease = boardInfoItem['month']
    }
  }
}

export function getBoardListData(total: any, today: any, yesterday: any, month: any, dataList: any[]) {
  const indicatorKeys: string[][] = [
    [
      'user_num',
      'gmv',
      'nationwide_member_recharge',
      'shop_member_recharge',
      'nationwide_member_consume',
      'shop_member_consume',
      'member_balance',
      'shop_member_balance',
      'sales_counter',
      'user_assistant',
      'cashier_assistant',
      'assistant_income',
      'cashier_product_sales',
      // 'total_platform_income',
      'billiard_chess_income',
      'billiard_chess_sales_income',
      'billiard_user_assistant_income',
      'total_open_duration',
      'total_meituan_shop_member_recharge',
      'total_entry_fee',
      'billiard_sport',
      'ios_register',
      'android_register'
    ],
    [
      'billiard_shop_income',
      'billiard_meituan',
      'billiard_douyin',
      'billiard_kuaishou',
      'billiard_shop_withdrawal',
      'billiard_shop_account',
      'billiard_order_num',
      'billiard_sales_counter',
      'billiard_sales_counter_income',
      'billiard_shop_num',
      'desk_num',
      'desk_open_num',
      'desk_open_rate',
      // 'billiard_platform_income',
      'billiard_divide_into_income',
      'billiard_sales_income',
      'billiard_user_assistant_income',
      'billiard_open_duration',
      'billiard_meituan_shop_member_recharge'
    ],
    [
      'chess_shop_income',
      'chess_meituan',
      'chess_douyin',
      'chess_kuaishou',
      'chess_shop_withdrawal',
      'chess_shop_account',
      'chess_order_num',
      'chess_sales_counter',
      'chess_sales_counter_income',
      'chess_shop_num',
      'room_num',
      'room_open_num',
      'room_open_rate',
      // 'chess_platform_income',
      'chess_divide_into_income',
      'chess_sales_income',
      'chess_open_duration',
      'chess_meituan_shop_member_recharge'
    ]
  ]

  const newDataList = deppLoopIns.deepCopy(dataList)

  for (let i = 0, len = indicatorKeys.length; i < len; ++i) {
    const keys = indicatorKeys[i]

    refreshDataBlockItem(total, today, yesterday, month, keys, newDataList[i].children)
  }

  return newDataList
}


export const dataBoardList: any[] = [
  {
    label: '总数据',
    children: [
      {
        label: '总用户数',
        icon: require('@/assets/dataStatisticBoard/total/user-count-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总GMV',
        icon: require('@/assets/dataStatisticBoard/total/gmv-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总通卡会员充值',
        icon: require('@/assets/dataStatisticBoard/total/member-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总单店卡充值',
        icon: require('@/assets/dataStatisticBoard/total/card-coupon-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总通卡会员消费',
        icon: require('@/assets/dataStatisticBoard/total/member-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总单店卡消费',
        icon: require('@/assets/dataStatisticBoard/total/card-coupon-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总通卡会员余额',
        icon: require('@/assets/dataStatisticBoard/total/member-icon.png'),
        value: 0,
        rightBottomIcon: require('@/assets/dataStatisticBoard/total/all-member-amount-right-bottom-icon.png'),
      },
      {
        label: '总单店卡余额',
        icon: require('@/assets/dataStatisticBoard/total/card-coupon-amount-icon.png'),
        value: 0,
        rightBottomIcon: require('@/assets/dataStatisticBoard/total/single-card-amount-right-bottom-icon.png'),

      },
      {
        label: '总售卖柜销售金额',
        icon: require('@/assets/dataStatisticBoard/total/sale-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总助教订单金额',
        icon: require('@/assets/dataStatisticBoard/total/total-coach-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总大店教练订单金额',
        icon: require('@/assets/dataStatisticBoard/total/total-coach-order-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总助教分成收益',
        icon: require('@/assets/dataStatisticBoard/total/coach-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总收银台商品销售金额',
        icon: require('@/assets/dataStatisticBoard/total/casher-goods-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '台球/棋牌（平台抽成）',
        icon: require('@/assets/dataStatisticBoard/total/card-coupon-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '售卖柜（平台抽成）',
        icon: require('@/assets/dataStatisticBoard/total/platform-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '助教（平台抽成）',
        icon: require('@/assets/dataStatisticBoard/total/coach-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      // {
      //   label: '技术服务费(平台抽成)',
      //   icon: require('@/assets/dataStatisticBoard/total/platform-income-icon.png'),
      //   value: 0,
      //   changeVars: {
      //     todayIncrease: 0,
      //     yesterdayIncrease: 0,
      //     monthIncrease: 0
      //   }
      // },
      {
        label: '总开台/开包厢时长(分钟)',
        icon: require('@/assets/dataStatisticBoard/total/open-play-duration-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '美团单店卡',
        icon: require('@/assets/dataStatisticBoard/total/card-coupon-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '报名费',
        icon: require('@/assets/dataStatisticBoard/total/sign-up-fee-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '体育券',
        icon: require('@/assets/dataStatisticBoard/total/card-coupon-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: 'IOS用户数',
        icon: require('@/assets/dataStatisticBoard/total/user-count-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: 'Android用户数',
        icon: require('@/assets/dataStatisticBoard/total/user-count-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
    ]
  },
  {
    label: '台球厅数据',
    children: [
      {
        label: '总台球店铺营业额',
        icon: require('@/assets/dataStatisticBoard/tablePool/shop-ball-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球美团券核销金额',
        icon: require('@/assets/dataStatisticBoard/tablePool/meituan-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球抖音券核销金额',
        icon: require('@/assets/dataStatisticBoard/tablePool/douyin-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球快手券核销金额',
        icon: require('@/assets/dataStatisticBoard/tablePool/kuaishou-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球店铺提现',
        icon: require('@/assets/dataStatisticBoard/tablePool/shop-withdraw-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球店铺余额',
        icon: require('@/assets/dataStatisticBoard/tablePool/shop-rest-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球订单数',
        icon: require('@/assets/dataStatisticBoard/tablePool/table-order-count-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球售卖柜销售',
        icon: require('@/assets/dataStatisticBoard/tablePool/offline-salebox-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球售卖柜分成金额',
        icon: require('@/assets/dataStatisticBoard/tablePool/salebox-divide-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球店铺数量',
        icon: require('@/assets/dataStatisticBoard/tablePool/shop-ball-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总台球桌数量',
        icon: require('@/assets/dataStatisticBoard/tablePool/table-count-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '开台数量',
        icon: require('@/assets/dataStatisticBoard/tablePool/open-table-count-icon.png'),
        value: 0,
        rightBottomIcon: require('@/assets/dataStatisticBoard/tablePool/open-table-count-right-bottom-icon.png'),
      },
      {
        label: '开台率',
        icon: require('@/assets/dataStatisticBoard/tablePool/open-table-rate-icon.png'),
        value: 0,
        rightBottomIcon: require('@/assets/dataStatisticBoard/tablePool/open-table-rate-right-bottom-icon.png'),
      },
      // {
      //   label: '技术服务费(平台抽成)',
      //   icon: require('@/assets/dataStatisticBoard/tablePool/platform-income-icon.png'),
      //   value: 0,
      //   changeVars: {
      //     todayIncrease: 0,
      //     yesterdayIncrease: 0,
      //     monthIncrease: 0
      //   }
      // },
      {
        label: '台球（平台抽成）',
        icon: require('@/assets/dataStatisticBoard/tablePool/meituan-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '售卖柜（平台抽成）',
        icon: require('@/assets/dataStatisticBoard/tablePool/platform-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '助教（平台抽成）',
        icon: require('@/assets/dataStatisticBoard/tablePool/coach-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总开台时长(分钟)',
        icon: require('@/assets/dataStatisticBoard/tablePool/open-play-duration-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '台球美团单店卡',
        icon: require('@/assets/dataStatisticBoard/tablePool/meituan-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
    ]
  },
  {
    label: '棋牌数据',
    children: [
      {
        label: '总棋牌店铺营业额',
        icon: require('@/assets/dataStatisticBoard/chessPool/shop-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌美团券核销金额',
        icon: require('@/assets/dataStatisticBoard/chessPool/meituan-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌抖音券核销金额',
        icon: require('@/assets/dataStatisticBoard/chessPool/douyin-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌快手券核销金额',
        icon: require('@/assets/dataStatisticBoard/chessPool/kuaishou-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌店铺提现',
        icon: require('@/assets/dataStatisticBoard/chessPool/shop-withdraw-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌店铺余额',
        icon: require('@/assets/dataStatisticBoard/chessPool/shop-rest-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌订单数',
        icon: require('@/assets/dataStatisticBoard/chessPool/order-count-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌售卖柜销售',
        icon: require('@/assets/dataStatisticBoard/chessPool/offline-salebox-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌售卖柜分成金额',
        icon: require('@/assets/dataStatisticBoard/chessPool/salebox-divide-amount-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌店铺数量',
        icon: require('@/assets/dataStatisticBoard/chessPool/shop-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '总棋牌包厢数量',
        icon: require('@/assets/dataStatisticBoard/chessPool/cage-count-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '开包厢数量',
        icon: require('@/assets/dataStatisticBoard/chessPool/open-cage-count-icon.png'),
        value: 0,
        rightBottomIcon: require('@/assets/dataStatisticBoard/chessPool/open-cage-count-right-bottom-icon.png'),
      },
      {
        label: '开包厢率',
        icon: require('@/assets/dataStatisticBoard/chessPool/open-cage-rate-icon.png'),
        value: 0,
        rightBottomIcon: require('@/assets/dataStatisticBoard/chessPool/open-cage-rate-right-bottom-icon.png'),
      },
      // {
      //   label: '技术服务费(平台抽成)',
      //   icon: require('@/assets/dataStatisticBoard/chessPool/platform-income-icon.png'),
      //   value: 0,
      //   changeVars: {
      //     todayIncrease: 0,
      //     yesterdayIncrease: 0,
      //     monthIncrease: 0
      //   }
      // },
      {
        label: '棋牌（平台抽成）',
        icon: require('@/assets/dataStatisticBoard/chessPool/meituan-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '售卖柜（平台抽成）',
        icon: require('@/assets/dataStatisticBoard/chessPool/platform-income-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },

      {
        label: '总开包厢时长(分钟)',
        icon: require('@/assets/dataStatisticBoard/chessPool/open-play-duration-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
      {
        label: '棋牌美团单店卡',
        icon: require('@/assets/dataStatisticBoard/chessPool/meituan-coupon-vertify-icon.png'),
        value: 0,
        changeVars: {
          todayIncrease: 0,
          yesterdayIncrease: 0,
          monthIncrease: 0
        }
      },
    ]
  }
]